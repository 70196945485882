<template lang="pug">
.num-range-row(v-if="startKey && !data[startKey]") 请先填写上一级水量区间
.num-range-row(v-else)
  el-input-number(
    v-model="startKey ? data[startKey] : defaultValue"
    disabled
    :controls="!!btnControls"
    :controls-position="btnControls")
  span.connect {{ connect }}
  el-input-number(
    v-bind="$attrs"
    v-on="$listeners"
    v-model="currentValue"
    :min="data[startKey] || min"
    :max="max"
    :controls="!!btnControls"
    :controls-position="btnControls")
  </template>

<script>

export default {
  name: 'QuantityRange',
  props: {
    value: [String, Number],
    connect: {
      type: String,
      default: '-'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    min: Number,
    max: Number,
    // 第一个计步器的值的valuekey
    startKey: {
      type: String,
      default: ''
    },
    triggerType: {
      type: String,
      default: 'stack'
    }
  },
  data () {
    return {
      defaultValue: 0
      // minValue: this.min ? this.min : 0,
      // maxValue: this.max ? this.max : 0
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    btnControls () {
      return this.transformVal(this.triggerType)
    }
  },
  methods: {
    transformVal (val) {
      const _Status = [
        { type: 'normal', value: '-' },
        { type: 'none', value: null },
        { type: 'stack', value: 'right' }
      ]
      const option = _Status.find(item => item.type === val)
      return option ? option.value : val
    }
  }
}
</script>

<style lang="sass" scoped>
.connect
  margin: 0 5px 0 5px
</style>

<style scoped>
.num-range-row >>> .el-input-number {
  width: auto !important;
}
</style>
