export default {
  data () {
    return {
      columns: [{
        name: 'priceName',
        label: '名称'
      }, {
        name: 'stepwise',
        label: '阶梯计费',
        render: { type: 'yesOrNo' }
      }],
      normalFields: [{
        name: 'water',
        label: '水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'sewage',
        label: '污水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'tax',
        label: '水资源费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }],
      stepwiseFields: [{
        name: 'quantity1',
        label: '一阶水量',
        render: { type: 'padString', suffix: 'm³' },
        form: {
          tag: 'number',
          colSpan: 3,
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'water1',
        label: '一阶水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'sewage1',
        label: '一阶污水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'tax1',
        label: '一阶水资源费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'quantity2',
        label: '二阶水量',
        render: { type: 'padString', suffix: 'm³' },
        form: {
          tag: 'number',
          colSpan: 3,
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'water2',
        label: '二阶水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'sewage2',
        label: '二阶污水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'tax2',
        label: '二阶水资源费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'quantity3',
        label: '三阶水量',
        render: { type: 'padString', suffix: 'm³' },
        form: {
          tag: 'number',
          colSpan: 3,
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'water3',
        label: '三阶水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'sewage3',
        label: '三阶污水费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'tax3',
        label: '三阶水资源费',
        render: { type: 'padString', suffix: '元' },
        form: {
          tag: 'number',
          rules: [{ required: true, message: '必填' }]
        }
      }]
    }
  },
  methods: {
    handleTransformResponse (response) {
      response.data = {
        total: response.data.length,
        data: response.data
      }
      this.$refs.table.setCurrentRow()
      return response
    }
  }
}
