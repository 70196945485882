<template lang="pug">
.wf-table.full-box
  .flex-table
    DataTable(
      ref="table"
      highlightClickRow
      :hasPage="false"
      resource="/price/list"
      :transformResponse="handleTransformResponse"
      :loading="loading"
      :filterFields="[]"
      :columns="columns"
      :toolbarButtonList="toolbarButtonList"
      :operateButtonList="operateButtonList"
      @current-change="handleCurrentChange")
  .price-box
    label.blue-header 价格详情
    .info-box
      .empty(v-if="!rowData.id") 请选择左侧价格方案
      DataInfo(
        v-if="rowData.id && !rowData.stepwise"
        :data="rowData"
        :fields="normalFields")
      el-collapse(
        v-if="rowData.id && rowData.stepwise"
        v-model="collapse")
        el-collapse-item(
          :title="`水量区间：0-${rowData.quantity1}m³`"
          name="1")
          DataInfo(
            :data="rowData"
            :fields="stepwiseFieldsObj.level1")
        el-collapse-item(
          :title="`水量区间：${rowData.quantity1}-${rowData.quantity2}m³`"
          name="2")
          DataInfo(
            :data="rowData"
            :fields="stepwiseFieldsObj.level2")
        el-collapse-item(
          :title="`水量区间：${rowData.quantity2}-${rowData.quantity3}m³`"
          name="3")
          DataInfo(
            :data="rowData"
            :fields="stepwiseFieldsObj.level3")
  el-drawer(
    :title="formTitle"
    size="90%"
    :modal="true"
    append-to-body
    :visible.sync="drawer"
    :before-close="handleClose"
    @open="handleDrawerOpen")
    DataForm.drawerForm(
      ref="form"
      v-model="formData"
      type="grid"
      :gridspanNum="3"
      gridFlow="row"
      :inlineGrid="true"
      :formFields="priceFields"
      :buttonList="formButtonList")
      PriceTableForm(
        v-if="formData.stepwise"
        slot="bodyFooter"
        v-model="formData"
        ref="priceForm")
  </template>

<script>
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import priceMixin from './mixin/common.js'
import PriceTableForm from './components/PriceTableForm.vue'

export default {
  name: 'PriceManage',
  components: { PriceTableForm },
  mixins: [priceMixin],
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '价格方案管理'
      }
    },
    priceFields () {
      const concatList = this.formData.stepwise ? [] : this.normalFields
      return this.priceBaseFields.concat(concatList)
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/file/priceManage/add' },
      { name: 'edit', url: '/file/priceManage/edit' },
      { name: 'delete', url: '/file/priceManage/delete' }
    ])
    return {
      // 侧拉表单是否展开
      drawer: false,
      // 表格是否loading
      loading: false,
      toolbarButtonList: actionRights.add ? [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.initFormData = {
            priceName: '',
            stepwise: false,
            water: 0,
            sewage: 0,
            tax: 0,
            quantity1: 0,
            water1: 0,
            sewage1: 0,
            tax1: 0,
            quantity2: 0,
            water2: 0,
            sewage2: 0,
            tax2: 0,
            quantity3: 0,
            water3: 0,
            sewage3: 0,
            tax3: 0
          }
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }] : [],
      operateButtonList: [{
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          this.initFormData = cloneDeep(data)
          this.formData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        showabled: actionRights.delete,
        func: this.handleDelete
      }],
      rowData: {},
      priceBaseFields: [{
        name: 'priceName',
        label: '价格方案',
        form: {
          tag: 'input',
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'stepwise',
        label: '阶梯计价',
        form: {
          tag: 'switch',
          colSpan: 2,
          rules: [{ required: true, message: '必填' }],
          chains: ({ chains, values, value }) => {
            // 普通计价
            this.normalFields.forEach(field => {
              chains.setValue(field.name, 0)
            })
            // 阶梯计价
            for (let i = 1; i < 4; i++) {
              ['quantity', 'water', 'sewage', 'tax'].forEach(key => {
                chains.setValue(`${key}${i}`, 0)
              })
            }
          }
        }
      }],
      // 表单数据
      formData: {},
      initFormData: {},
      // 表单提交类型
      formSubType: '', // add/edit
      formButtonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: async (form) => {
          try {
            const validateResult = await this.validate()
            if (!validateResult) return false
            switch (this.formSubType) {
              case 'add':
                return await this.handleAdd(form)
              case 'edit':
                return await this.handleEdit(form)
              default:
                break
            }
            return true
          } catch (error) {
            return false
          }
        }
      }, {
        label: '重置',
        func: () => {
          this.resetForm()
        }
      }],
      collapse: ['1', '2', '3'],
      stepwiseFieldsObj: [
        { name: 'water', label: '水费' },
        { name: 'sewage', label: '污水费' },
        { name: 'tax', label: '水资源费' }
      ].reduce((res, item) => {
        for (let i = 1; i < 4; i++) {
          res[`level${i}`].push({
            name: `${item.name}${i}`,
            label: item.label,
            render: { type: 'padString', suffix: '元' }
          })
        }
        return res
      }, {
        level1: [],
        level2: [],
        level3: []
      })
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.table.reRenderTableData()
    },
    handleCurrentChange (row) {
      this.rowData = row || {}
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.resetForm()
    },
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.$nextTick(() => {
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataform.clearValidate()
      })
    },
    async validate () {
      try {
        await this.$refs.form.$refs.dataform.validate()
        this.formData.stepwise && await this.$refs.priceForm.validate()
        return true
      } catch (error) {
        return false
      }
    },
    handleAdd (form) {
      return new Promise((resolve, reject) => {
        const { formData } = form
        this.$post({
          url: '/price/save',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.resetForm()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleEdit (form) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/price/update/${form.formData.id}`,
          data: form.formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: `/price/delete/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('删除成功!')
            this.getData()
            resolve('success')
          })
          .catch(reject)
      })
    }
  },
  created () {
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  background-color: #eee
  width: 100%
  height: 100%
  .flex-table
    width: calc( 100% - 240px )
    height: 100%
  .price-box
    width: 232px
    height: calc( 100% - 16px )
    margin-left: 8px
    padding: 8px
    background-color: #fff
    .empty
      padding: 8px 0
    .info-box
      height: calc( 100% - 40px )
      overflow-y: auto
      padding-top: 8px
.drawerForm
  padding: 8px
</style>
