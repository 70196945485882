<template lang="pug">
el-form.select-main(
  key="formkey"
  ref="form"
  hide-required-asterisk
  inline-message
  :model="formData"
  :rules="rules"
  @validate="recodeValidate")
  table.priceTable
    thead
      tr
        th(
          v-for="(f, i) in fieldList"
          :key="`header${i}`")
            span {{ f.title }}
            span.required(v-if="!readOnly") *
    tbody
      tr(
        v-for="(rI, rIndex) in 3"
        :key="`row${rIndex}`")
        td(
          v-for="(field, idx) in fieldList"
          :key="`body${idx}`")
          DataformItem(
            v-model="formData[field.cols[rIndex].name]"
            v-bind="field.cols[rIndex].form"
            :field="field.cols[rIndex]"
            :full="formData"
            :key="field.cols[rIndex].name"
            type="row"
            :readOnly="readOnly"
            :failFields="validateFailFields"
            :otherFormItem="otherFormItem"
            @onValueChange="handleChange")
</template>

<script>
import DataformItem from '@/components/Dataform/DataformItem'
import QuantityRange from './QuantityRange.vue'

export default {
  name: 'PriceTableForm',
  components: { DataformItem, QuantityRange },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    rules () {
      const rules = this.fieldList.reduce((result, current) => {
        current.cols.forEach(field => {
          if (field.form.rules) result[field.name] = field.form.rules
        })
        return result
      }, {})
      return rules
    }
  },
  data () {
    const numberForm = {
      tag: 'number',
      rules: [{ required: true, message: '必填' }],
      min: 0
    }
    return {
      validateFailFields: {},
      otherFormItem: {
        quantity: QuantityRange
      },
      fieldList: [{
        title: '水量区间(m³)',
        cols: [{
          name: 'quantity1',
          form: {
            tag: 'quantity',
            rules: [{ required: true, message: '必填' }],
            min: 0
          },
          render: (value, field, data) => {
            return `0-${value}`
          }
        }, {
          name: 'quantity2',
          form: {
            tag: 'quantity',
            rules: [{ required: true, message: '必填' }],
            startKey: 'quantity1'
          },
          render: (value, field, data) => {
            return `${data.quantity1}-${value}`
          }
        }, {
          name: 'quantity3',
          form: {
            tag: 'quantity',
            rules: [{ required: true, message: '必填' }],
            startKey: 'quantity2'
          },
          render: (value, field, data) => {
            return `${data.quantity2}-${value}`
          }
        }]
      }, {
        title: '水费(元)',
        cols: [{
          name: 'water1',
          form: numberForm
        }, {
          name: 'water2',
          form: numberForm
        }, {
          name: 'water3',
          form: numberForm
        }]
      }, {
        title: '污水费(元)',
        cols: [{
          name: 'sewage1',
          form: numberForm
        }, {
          name: 'sewage2',
          form: numberForm
        }, {
          name: 'sewage3',
          form: numberForm
        }]
      }, {
        title: '水资源费(元)',
        cols: [{
          name: 'tax1',
          form: numberForm
        }, {
          name: 'tax2',
          form: numberForm
        }, {
          name: 'tax3',
          form: numberForm
        }]
      }]
    }
  },
  methods: {
    handleChange (name, value) {
      const { quantity2, quantity3 } = this.formData
      switch (name) {
        case 'quantity1':
          if (quantity2 <= value) this.formData.quantity2 = value + 1
          if (quantity3 <= quantity2) this.formData.quantity3 = quantity2 + 1
          break
        case 'quantity2':
          if (quantity3 <= value) this.formData.quantity3 = value + 1
          break
        default:
          break
      }
    },
    recodeValidate (fieldName, flag, message) {
      if (!flag) {
        this.$set(this.validateFailFields, fieldName, message)
      } else if (flag && Object.hasOwnProperty.call(this.validateFailFields, fieldName)) {
        this.$set(this.validateFailFields, fieldName, null)
        delete this.validateFailFields[fieldName]
      }
    },
    validate () {
      return this.$refs.form.validate()
    }
  }
}
</script>

<style lang="sass" scoped>
.priceTable
  width: 100%
  margin-top: 8px
  border-collapse: collapse
  border: 1px solid $--border-color-base
  tbody
    tr
      border-top: 1px solid $--border-color-base
  th+th, td+td
    border-left: 1px solid $--border-color-base
  tr, th, td
    padding: 0
    text-align: center
    line-height: $--table-wrapper-line-height
    font-size: 14px
    color: $--color-info
  td
    padding: 4px 0
  .required
    color: $--color-danger
    margin-left: 2px
</style>
<style scoped>
.priceTable >>> .el-form-item {
  margin-bottom: unset;
}
</style>
